import { useTheme } from '@emotion/react'
import {
  animated,
  useTrail,
  useSpring,
  useChain,
  useSpringRef,
} from 'react-spring'
import { useInView } from 'react-intersection-observer'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

import { OtherProductsItem as Item } from './OtherProductsItem'
import { PRODUCTS } from './constants'

const AnimatedItem = animated(Item)

type Props = {
  id?: string
}

export function OtherProducts({ id }: Props) {
  const theme = useTheme()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })

  const springRef = useSpringRef()
  const trailRef = useSpringRef()

  const headerSpring = useSpring({
    transform: inView ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 32px, 0)',
    opacity: inView ? 1 : 0,
    ref: springRef,
  })

  const trails = useTrail(PRODUCTS.length, {
    transform: inView ? 'scale(1)' : 'scale(0.9)',
    opacity: inView ? 1 : 0,
    config: DEFAULT_SPRING_CONFIG,
    ref: trailRef,
  })

  useChain([springRef, trailRef], [0, 1])

  return (
    <section
      id={id}
      ref={ref}
      css={theme.mq({
        ...theme.layerStyles.landingSectionContainer,
      })}
    >
      <animated.h2
        style={headerSpring}
        css={theme.mq({ ...theme.textStyles.landingSectionHeader })}
      >
        บริการอื่น ๆ ของเรา
      </animated.h2>
      <div
        css={theme.mq({
          display: 'grid',
          gridTemplateColumns: {
            base: '1fr',
            md: 'repeat(4, 1fr)',
          },
          gridGap: {
            base: '--space-4',
            sm: '--space-8',
          },
        })}
      >
        {PRODUCTS.map(({ sx, ...product }, index) => {
          return (
            <AnimatedItem
              key={product.title}
              style={trails[index]}
              {...product}
              sx={{
                ...sx,
                transform: 'scale(0.9)',
                opacity: 0,
              }}
            />
          )
        })}
      </div>
    </section>
  )
}
