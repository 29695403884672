import { URL, ScrollOption } from '@/lib/link'

type Link = {
  label: React.ReactNode
  scrollOption?: ScrollOption
} & URL

export const LINKS: Link[] = [
  {
    to: 'LANDING',
    label: 'หน้าแรก',
    scrollOption: {
      hashSpy: false,
    },
  },
  {
    to: 'OTHER_PRODUCTS',
    label: 'ผลิตภัณฑ์ของเรา',
  },
  {
    to: 'GET_STARTED',
    label: 'เริ่มต้นและวิธีการใช้',
  },
  {
    to: 'FAQ',
    label: 'FAQs',
  },
]
