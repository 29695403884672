export const CHECK_LIST: {
  label: React.ReactNode
  isChecked?: boolean
}[] = [
  {
    label: 'ระบบการจัดการความยินยอมแยกตามวัตถุประสงค์ที่ออกแบบให้ใช้งานง่าย',
  },
  {
    label: 'รองรับการใช้งานทั้งแบบ API และ URL',
  },
  {
    label:
      'สร้างโดยผู้พัฒนาที่ได้รับมาตรฐาน ISO27001 และ ISO27701 ร่วมกับทีมนักกฎหมายเชี่ยวชาญ',
  },
  {
    label: 'สอดคล้องตาม PDPA และ GDPR',
  },
  {
    label: 'มี Live Chat และทีมงานพร้อมให้ความช่วยเหลือตลอดเวลาทำการ',
  },
]
