import { forwardRef } from 'react'
import Image from 'next/image'
import { useTheme } from '@emotion/react'

type Props = {
  label: React.ReactNode
  isChecked?: boolean
}

export const Checked = forwardRef(function Checked(
  { label, isChecked = true }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme()

  return (
    <div
      ref={ref}
      css={theme.mq({
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'flex-start',
        textAlign: 'left',
      })}
    >
      <figure
        css={theme.mq({
          width: {
            base: '--space-6',
            sm: '--space-8',
          },
          height: {
            base: '--space-6',
            sm: '--space-8',
          },
          position: 'relative',
          marginTop: { base: 0, sm: '2px' },
          filter: isChecked ? 'none' : 'grayscale(100%)',
          opacity: isChecked ? 1 : 0.5,
          flex: '0 0 auto',
        })}
      >
        <Image
          src="/images/landing/why-us/checked.svg"
          alt="checked"
          layout="fill"
        />
      </figure>
      <p
        css={theme.mq({
          fontSize: {
            base: '--fontSizes-md',
            sm: '--fontSizes-xl',
          },
          fontWeight: '--fontWeights-light',
          marginLeft: '--space-4',
        })}
      >
        {label}
      </p>
    </div>
  )
})
