import { GetStaticPropsContext, GetStaticPropsResult } from 'next'

import {
  LandingPage,
  Props as LandingPageProps,
} from '@/modules/landing/pages/LandingPage'

export async function getStaticProps(
  _: GetStaticPropsContext,
): Promise<GetStaticPropsResult<LandingPageProps>> {
  return {
    props: {},
  }
}

export default LandingPage
