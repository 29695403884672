import { Props as ItemProps } from './OtherProductsItem'
import { COOKIE_WOW, LEARN_PDPA, PDPA_FORM, PDPA_PRO, PROKIT } from '@/routes'

// import LogoCookiewow from '@/images/landing/other-products/logo-cookiewow.svg'
// import LogoPDPAPro from '@/images/landing/other-products/logo-pdpapro.svg'
// import LogoPDPAProkit from '@/images/landing/other-products/logo-prokit.svg'
// import LogoPDPAForm from '@/images/landing/other-products/logo-pdpaform.svg'
// import LogoLearnPDPA from '@/images/landing/other-products/logo-learnpdpa.svg'

// import BgCookiewow from '@/images/landing/other-products/bg-cookiewow.svg'
// import BgPDPAPro from '@/images/landing/other-products/bg-pdpapro.svg'
// import BgPDPAProkit from '@/images/landing/other-products/bg-prokit.svg'
// import BgPDPAForm from '@/images/landing/other-products/bg-pdpaform.svg'
// import BgLearnPDPA from '@/images/landing/other-products/bg-learnpdpa.svg'

export const PRODUCTS: ItemProps[] = [
  {
    primaryColor: '#EA834F',
    bgImage: '/images/landing/other-products/bg-cookiewow.svg',
    logoImage: '/images/landing/other-products/logo-cookiewow.svg',
    // bgImage: BgCookiewow,
    // logoImage: LogoCookiewow,
    subtitle: 'สร้างแบนเนอร์คุกกี้ขอความยินยอม',
    title: 'Cookie Wow',
    href: COOKIE_WOW.pathname,
    sx: {
      gridColumn: {
        base: '1',
        md: ' span 2',
      },
    },
  },
  {
    primaryColor: '#00E3AB',
    secondaryColor: '#32C4D7',
    bgImage: '/images/landing/other-products/bg-pdpapro.svg',
    logoImage: '/images/landing/other-products/logo-pdpapro.svg',
    // bgImage: BgPDPAPro,
    // logoImage: LogoPDPAPro,
    subtitle: 'สร้าง Privacy Policy แบบมือโปร',
    title: 'PDPA Pro',
    href: PDPA_PRO.pathname,
    sx: {
      gridColumn: {
        base: '1',
        md: ' span 2',
      },
    },
  },
  {
    primaryColor: '#00254C',
    bgImage: '/images/landing/other-products/bg-prokit.svg',
    logoImage: '/images/landing/other-products/logo-prokit.svg',
    // bgImage: BgPDPAProkit,
    // logoImage: LogoPDPAProkit,
    subtitle: 'ชุดเอกสารเตรียมความพร้อม PDPA',
    title: 'PDPA Prokit',
    href: PROKIT.pathname,
    sx: {
      gridColumn: {
        base: '1',
        md: ' span 2',
      },
    },
  },
  {
    primaryColor: '#2879F9',
    bgImage: '/images/landing/other-products/bg-pdpaform.svg',
    logoImage: '/images/landing/other-products/logo-pdpaform.svg',
    // bgImage: BgPDPAForm,
    // logoImage: LogoPDPAForm,
    subtitle: 'สร้างแบบ Form รับคำร้อง PDPA',
    title: 'PDPA Form',
    href: PDPA_FORM.pathname,
    sx: {
      gridColumn: {
        base: '1',
        md: ' span 2',
      },
    },
  },
  {
    primaryColor: '#104EBC',
    bgImage: '/images/landing/other-products/bg-learnpdpa.svg',
    logoImage: '/images/landing/other-products/logo-learnpdpa.svg',
    // bgImage: BgLearnPDPA,
    // logoImage: LogoLearnPDPA,
    subtitle: 'คอร์สออนไลน์เรื่อง PDPA',
    title: 'Learn PDPA',
    href: LEARN_PDPA.pathname,
    sx: {
      gridColumn: {
        base: '1',
        md: '2 / span 2',
      },
    },
  },
]
