import Image from 'next/image'
import { useTheme } from '@emotion/react'

import { Address } from './Address'
import { Links } from './Links'

type Props = {
  id?: string
}

export function Contact({ id }: Props) {
  const theme = useTheme()

  return (
    <section
      css={theme.mq({
        backgroundColor: '--colors-tertiary-900',
        width: '--sizes-full',
        display: 'flex',
        flexFlow: 'column nowrap',
      })}
    >
      <div
        id={id}
        css={theme.mq({
          ...theme.layerStyles.landingSectionContainer,
          paddingTop: 0,
          // paddingBottom: '--space-16',
          color: 'white',
          display: 'grid',
          gridTemplateColumns: {
            base: 'minmax(0, 1fr)',
            sm: '0.3fr 0.7fr',
            md: '0.45fr 0.2fr 0.45fr',
          },
          gridGap: { base: '--space-10', md: '--space-16' },
          alignItems: 'flex-start',
        })}
      >
        <div
          css={theme.mq({
            display: {
              base: 'none',
              md: 'flex',
            },
            position: 'relative',
            flexFlow: 'column nowrap',
            alignItems: 'flex-start',
          })}
        >
          <Image
            src="/images/logo-alternate.svg"
            alt="logo"
            width="356px"
            height="55px"
          />
          <iframe
            src="https://maps.google.com/maps?q=NDATATHOTH%20COMPANY%20LIMITED&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            title="ndatathoth-map"
            className="map"
            css={theme.mq({
              marginTop: '--space-10',
              width: '--sizes-full',
              height: '200px',
            })}
          ></iframe>
        </div>
        <Links />
        <Address />
      </div>
    </section>
  )
}
