import { ExternalLink } from '@/components/ExternalLink'
import { COOKIE_WOW } from '@/routes'

export const FAQS: {
  title: React.ReactNode
  content: React.ReactNode
  isOpen?: boolean
}[] = [
  {
    title: 'Consent Wow คืออะไร ?',
    content: (
      <>
        <p>
          Consent Wow คือผู้ให้บริการจัดการความยินยอม (Consent Management)
          ที่ให้บริการผ่านทางเว็บไซต์ โดยช่วยให้ผู้ใช้สามารถ
        </p>
        <ul>
          <li>
            จัดการความยินยอมจากหลากหลายเว็บไซต์หรือแอปพลิเคชันต่าง ๆ ในที่เดียว
          </li>
          <li>รองรับการทำงานผ่าน API และ URL</li>
          <li>สร้างแบบฟอร์มขอความยินยอมสอดคล้องตามกฎหมาย PDPA</li>
          <li>นำเข้ารายการความยินยอม</li>
          <li>จัดเก็บทุกรายการความยินยอมตามวัตถุประสงค์ไว้บนคลาวด์</li>
          <li>
            จัดเก็บรายละเอียดที่ให้ความยินยอม ข้อมูลผู้ให้ความยินยอม
            วัตถุประสงค์ของการขอความยินยอม วันที่และเวลาที่ให้ความยินยอม
          </li>
          <li>อัปเดตความยินยอมได้โดยอัตโนมัติ</li>
          <li>
            ตรวจสอบประวัติและสถานะความยินยอมล่าสุดของเจ้าของข้อมูลส่วนบุคคล
          </li>
          <li>ตรวจสอบและเรียกใช้รายการความยินยอมได้ทุกที่ ทุกเวลา</li>
        </ul>
      </>
    ),
  },
  {
    title: 'หากไม่มีการจัดการความยินยอมจะถือว่าผิดกฎหมายหรือไม่ ?',
    content: (
      <p>
        ถึงแม้ PDPA
        ในปัจจุบันจะไม่ได้มีการกำหนดเรื่องการเก็บหลักฐานการขอความยินยอมจากตัวเจ้าของข้อมูลไว้เป็นการเฉพาะ
        แต่การขาดซึ่งกระบวนการหรือระบบจัดการเรื่องความยินยอมของเจ้าของข้อมูลจะถือเป็นความเสี่ยงต่อองค์กรในการปฏิบัติให้เป็นไปตาม
        PDPA เมื่อมีการตรวจสอบโดยสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
        (“สคส“)
        แล้วทางองค์กรไม่สามารถแสดงหลักฐานว่ามีการขอความยินยอมมาจากเจ้าของข้อมูลอย่างไรหรือขอมาก่อนการประมวลผลข้อมูลหรือไม่
        ดังนั้น
        การจัดเตรียมซึ่งระบบการจัดการความยินยอมของเจ้าของข้อมูลจึงเป็นเรื่องที่ทุกองค์กรควรให้ความสำคัญเพราะจะเป็นสิ่งที่ช่วยยืนยันว่าองค์กรได้ปฏิบัติให้เป็นไปตาม
        PDPA เช่น
        แสดงให้เห็นได้ว่าความยินยอมนั้นได้รับมาก่อนทำการส่งข้อมูลข่าวสารให้แก่ลูกค้าหรือก่อนที่ลูกค้าจะทำแบบสอบถาม
        เป็นต้น
      </p>
    ),
  },
  {
    title:
      'Consent Wow ช่วยเรื่องการจัดการความยินยอมในการใช้คุกกี้ (Cookie Consent) ได้หรือไม่ ?',
    content: (
      <p>
        Consent Wow
        จะเหมาะสมที่สุดสำหรับการจัดการความยินยอมที่รวบรวมผ่านแบบฟอร์มออนไลน์และออฟไลน์
        โดยเรามีโซลูชันที่ช่วยจัดการความยินยอมคุกกี้คือ{' '}
        <ExternalLink
          href={COOKIE_WOW.pathname}
          layerStyle="actionable"
          sx={{
            color: '--colors-primary-500',
            textDecoration: 'underline',
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          Cookie Wow
        </ExternalLink>{' '}
        ที่ช่วยให้คุณสร้างและปรับแต่งแบนเนอร์คุกกี้สอดคล้อง PDPA ในไม่กี่นาที
        มาพร้อมระบบสแกนเว็บไซต์และจัดเก็บความยินยอมอัตโนมัติในที่เดียว
      </p>
    ),
  },
]
