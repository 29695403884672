import { useTheme } from '@emotion/react'

import { Accordion } from '@/components/Accordion'
import { FAQS } from './constants'

type Props = {
  id?: string
}

export function FAQs({ id }: Props) {
  const theme = useTheme()

  return (
    <section
      id={id}
      css={theme.mq({
        ...theme.layerStyles.landingSectionContainer,
      })}
    >
      <h2 css={theme.mq({ ...theme.textStyles.landingSectionHeader })}>FAQ</h2>
      <div
        css={theme.mq({
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridGap: { base: '--space-6', sm: '--space-8' },
        })}
      >
        {FAQS.map(({ title, content, isOpen }, index) => {
          return (
            <Accordion key={`${index}`} title={title} defaultIsOpen={isOpen}>
              {content}
            </Accordion>
          )
        })}
      </div>
    </section>
  )
}
