import { ResponsiveCSSObject, Size } from '@/styles/types'
import { themeCSSPrefix } from '@/styles/constants'

import { toVarRef } from '@/lib/utils'

export const baseStyle: ResponsiveCSSObject = {
  boxShadow: 'none',
  outline: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  transitionProperty: '--transition-property-common',
  transitionDuration: '--transition-duration-normal',
  transitionTimingFunction: '--transition-easing-ease-in-out',
  overflow: 'hidden',
  fontWeight: '--fontWeights-light',
  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed !important',
  },
  '&::placeholder': {
    opacity: 0.8,
    color: '--colors-gray-500',
  },
}

export const sizes: Partial<Record<Size, ResponsiveCSSObject>> = {
  xl: {
    fontSize: '--fontSizes-lg',
    paddingLeft: '--space-6',
    paddingRight: '--space-6',
    height: '--space-14',
    borderRadius: '--radii-md',
  },

  lg: {
    fontSize: '--fontSizes-md',
    paddingLeft: '--space-4',
    paddingRight: '--space-4',
    height: '--space-12',
    borderRadius: '--radii-md',
  },

  md: {
    fontSize: '--fontSizes-md',
    paddingLeft: '--space-4',
    paddingRight: '--space-4',
    height: '--space-10',
    borderRadius: '--radii-md',
  },

  sm: {
    fontSize: '--fontSizes-sm',
    paddingLeft: '--space-3',
    paddingRight: '--space-3',
    height: '--space-8',
    borderRadius: '--radii-sm',
  },

  xs: {
    fontSize: '--fontSizes-xs',
    paddingLeft: '--space-2',
    paddingRight: '--space-2',
    height: '--space-6',
    borderRadius: '--radii-sm',
  },
}

function getDefaults(colorScheme: string) {
  return {
    focusBorderColor: `--colors-${colorScheme}-500`,
    errorBorderColor: '--colors-red-500',
  }
}

const variantOutline = (colorScheme: string): ResponsiveCSSObject => {
  const { focusBorderColor: fc, errorBorderColor: ec } =
    getDefaults(colorScheme)

  return {
    border: '1px solid',
    borderColor: '--colors-gray-300',
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: '--colors-gray-500',
    },
    '&:read-only': {
      boxShadow: 'none !important',
      userSelect: 'all',
    },
    '&:disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    '&:invalid': {
      borderColor: ec,
      boxShadow: `0 0 0 1px ${toVarRef(ec, themeCSSPrefix)}`,
    },
    '&:focus': {
      zIndex: 1,
      borderColor: fc,
      boxShadow: `0 0 0 1px ${toVarRef(fc, themeCSSPrefix)}`,
    },
  }
}

const variantFilled = (colorScheme: string) => {
  const { focusBorderColor: fc, errorBorderColor: ec } =
    getDefaults(colorScheme)

  return {
    border: '2px solid',
    borderColor: 'transparent',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '--colors-gray-50',
    },
    '&:read-only': {
      boxShadow: 'none !important',
      userSelect: 'all',
    },
    '&:disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    '&:invalid': {
      borderColor: ec,
    },
    '&:focus': {
      borderColor: fc,
    },
  }
}

const variantFlushed = (colorScheme: string) => {
  const { focusBorderColor: fc, errorBorderColor: ec } =
    getDefaults(colorScheme)

  return {
    borderBottom: '1px solid',
    borderColor: '--colors-gray-300',
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: '--colors-gray-500',
    },
    '&:read-only': {
      boxShadow: 'none !important',
      userSelect: 'all',
    },
    '&:invalid': {
      borderColor: ec,
      boxShadow: `0px 1px 0px 0px ${toVarRef(ec, themeCSSPrefix)}}`,
    },
    '&:focus': {
      borderColor: fc,
      boxShadow: `0px 1px 0px 0px ${toVarRef(fc, themeCSSPrefix)}`,
    },
  }
}

const variantUnstyled = (_: string) => {
  return {
    backgroundColor: 'transparent',
    px: 0,
    height: 'auto',
  }
}

export const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
}
