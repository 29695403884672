import { forwardRef } from 'react'
import Image from 'next/image'
import { useTheme } from '@emotion/react'

import { ResponsiveCSSObject } from '@/styles/types'

export type Props = {
  index: number
  icon: StaticImageData | string
  title: React.ReactNode
  subtitle: React.ReactNode
  sx?: ResponsiveCSSObject
}

export const GetStartedItem = forwardRef(function GetStartedItem(
  { index, icon, title, subtitle, sx }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme()

  return (
    <div
      ref={ref}
      css={theme.mq({
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '320px',
        ...sx,
      })}
    >
      <Image src={icon} alt={`${index}`} width="88px" height="88px" />
      <p
        css={theme.mq({
          fontSize: {
            base: '96px',
            sm: '72px',
          },
          fontWeight: '--fontWeights-semibold',
          color: '--colors-secondary-300',
          lineHeight: 1,
          marginTop: '--space-3',
        })}
      >
        {`${index + 1}`.padStart(2, '0')}
      </p>
      <p
        css={theme.mq({
          fontSize: {
            base: '--fontSizes-2xl',
            sm: '--fontSizes-3xl',
          },
          fontWeight: '--fontWeights-semibold',
          color: '--colors-primary-500',
          marginTop: '--space-2',
        })}
      >
        {title}
      </p>
      <p
        css={theme.mq({
          fontSize: {
            base: '--fontSizes-md',
            sm: '--fontSizes-xl',
          },
          fontWeight: '--fontWeights-light',
          marginTop: '--space-2',
        })}
      >
        {subtitle}
      </p>
    </div>
  )
})
