import { useTheme } from '@emotion/react'
import {
  animated,
  useTrail,
  useSpring,
  useChain,
  useSpringRef,
} from 'react-spring'
import { useInView } from 'react-intersection-observer'
import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

import { InfoItem } from './InfoItem'
import { ITEMS } from './constants'

const AnimatedItem = animated(InfoItem)

type Props = {
  id?: string
}

export function Info({ id }: Props) {
  const theme = useTheme()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })

  const springRef = useSpringRef()
  const trailRef = useSpringRef()

  const headerSpring = useSpring({
    transform: inView ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 32px, 0)',
    opacity: inView ? 1 : 0,
    ref: springRef,
  })

  const trails = useTrail(ITEMS.length, {
    transform: inView ? 'scale(1)' : 'scale(0.9)',
    opacity: inView ? 1 : 0,
    config: DEFAULT_SPRING_CONFIG,
    ref: trailRef,
  })

  useChain([springRef, trailRef], [0, 1])

  return (
    <section
      css={theme.mq({
        marginTop: { md: '-24px' },
        position: 'relative',
        width: '--sizes-full',
      })}
    >
      <div
        css={theme.mq({
          position: 'absolute',
          width: '--sizes-full',
          height: '240px',
          top: 0,
          left: 0,
          bottom: 0,
          transform: { base: 'translate3d(0, -10%, 0)', sm: 'none' },
          backgroundImage: 'url(/images/landing/header-bg.svg)',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
          backgroundSize: '50px',
        })}
      ></div>
      <div
        id={id}
        ref={ref}
        css={theme.mq({
          ...theme.layerStyles.landingSectionContainer,
          position: 'relative',
          zIndex: '1',
        })}
      >
        <animated.div style={headerSpring}>
          <h2
            css={theme.mq({
              ...theme.textStyles.landingSectionHeader,
              marginBottom: 0,
              backgroundColor: 'white',
              alignSelf: 'center',
              padding: '--space-2',
              paddingBottom: '--space-2',
              paddingLeft: {
                base: '--space-2',
                md: '--space-10',
              },
              paddingRight: {
                base: '--space-2',
                md: '--space-10',
              },
              width: 'fit-content',
              margin: 'auto',
            })}
          >
            จัดการความยินยอมด้วย{' '}
            <span css={theme.mq({ whiteSpace: 'nowrap' })}>Consent Wow</span>
          </h2>
          <p
            css={theme.mq({
              ...theme.textStyles.landingSectionSubheader,
              marginBottom: 0,
              paddingBottom:
                theme.textStyles.landingSectionSubheader.marginBottom,
              backgroundColor: 'white',
              alignSelf: 'center',
              paddingTop: '--space-2',
            })}
          >
            การมีหลักฐานว่าองค์กรได้รับความยินยอมจากเจ้าของข้อมูลก่อนเริ่มการประมวลผลข้อมูลส่วนบุคคลจะเป็นสิ่งสำคัญที่ช่วยยืนยันว่าการประมวลผลเป็นไปตาม
            PDPA{' '}
            <span
              css={theme.mq({
                color: '--colors-primary-500',
                fontWeight: '--fontWeights-medium',
              })}
            >
              Consent Wow
            </span>{' '}
            ช่วยให้การจัดการความยินยอมเป็นเรื่องง่าย
          </p>
        </animated.div>
        <div
          css={theme.mq({
            display: 'grid',
            gridTemplateColumns: {
              base: '1fr',
              md: 'repeat(3, 1fr)',
            },
            gridGap: '--space-7',
            justifyItems: 'center',
          })}
        >
          {ITEMS.map((item, index) => {
            return (
              <AnimatedItem
                key={`${index}`}
                style={trails[index]}
                sx={{
                  transform: 'scale(0.9)',
                  opacity: 0,
                }}
                {...item}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}
