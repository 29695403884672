import { forwardRef } from 'react'
import Image from 'next/image'
import { useTheme } from '@emotion/react'

import { ResponsiveCSSObject } from '@/styles/types'

export type Props = {
  icon: StaticImageData | string
  title: React.ReactNode
  subtitle: React.ReactNode
  sx?: ResponsiveCSSObject
}

export const InfoItem = forwardRef(function InfoItem(
  { icon, title, subtitle, sx }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme()

  return (
    <div
      ref={ref}
      css={theme.mq({
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        textAlign: 'center',
        padding: { base: '--space-6', sm: '--space-8' },
        boxShadow: '--shadows-xl',
        backgroundColor: 'white',
        maxWidth: '320px',
        ...sx,
      })}
    >
      <Image src={icon} alt="" width="88px" height="88px" />
      <p
        css={theme.mq({
          fontSize: {
            base: '--fontSizes-xl',
            sm: '--fontSizes-3xl',
          },
          fontWeight: '--fontWeights-semibold',
          marginTop: '--space-3',
        })}
      >
        {title}
      </p>
      <p
        css={theme.mq({
          fontSize: {
            base: '--fontSizes-md',
            sm: '--fontSizes-xl',
          },
          fontWeight: '--fontWeights-light',
          marginTop: '--space-2',
        })}
      >
        {subtitle}
      </p>
    </div>
  )
})
