import { useTheme } from '@emotion/react'

import env from '@/lib/env'
import { Button } from '@/components/Button'
import { HeroContainer } from './HeroContainer'

type Props = {
  id?: string
}

export function Hero({ id }: Props) {
  const theme = useTheme()

  return (
    <HeroContainer id={id}>
      <div
        css={theme.mq({
          display: 'flex',
          flexFlow: 'column nowrap',
          zIndex: 1,
          paddingTop: {
            base: 0,
            md: '--space-10',
          },
          paddingBottom: {
            base: 0,
            md: '--space-10',
          },
        })}
      >
        <h1
          css={theme.mq({
            ...theme.textStyles.landingSectionHero,
            letterSpacing: '-1.5%',
            marginBottom: '--space-4',
          })}
        >
          ให้การจัดการความยินยอมของธุรกิจคุณเป็นเรื่องง่าย ๆ
        </h1>
        <h2 css={theme.mq({ ...theme.textStyles.landingSectionSubHero })}>
          จัดการ บันทึกคำขอความยินยอม
          และสร้างแบบฟอร์มขอความยินยอมของลูกค้าหรือผู้ใช้งานของคุณให้สอดคล้อง
          PDPA ได้ง่าย ๆ ในที่เดียว
        </h2>
        <a
          href={`${env.PLATFORM_URL}/sign-up`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            sx={{
              fontSize: {
                base: '--fontSizes-md',
                xs: '20px',
              },
              height: {
                base: '--space-10',
                xs: '--space-16',
              },
              width: '--sizes-full',
            }}
          >
            เริ่มใช้งาน
          </Button>
        </a>
      </div>
    </HeroContainer>
  )
}
