import { MainLayout } from '@/layouts/MainLayout'

import { Hero } from '@/modules/landing/sections/Hero'
import { Contact } from '@/modules/landing/sections/Contact'
import { FAQs } from '@/modules/landing/sections/FAQ'
import { GetStarted } from '@/modules/landing/sections/GetStarted'
import { Price } from '@/modules/landing/sections/Price'
import { Info } from '@/modules/landing/sections/Info'
import { ISO } from '@/modules/landing/sections/ISO'
import { NoticeForm } from '@/modules/landing/sections/NoticeForm'
import { OtherProducts } from '@/modules/landing/sections/OtherProducts'
import { QuotationForm } from '@/modules/landing/sections/QuotationForm'
import { WhyUs } from '@/modules/landing/sections/WhyUs'

import * as ROUTES from '@/routes'

export type Props = {}

export function LandingPage() {
  return (
    <>
      <Hero id={ROUTES.LANDING.hash} />
      <Info id={ROUTES.INFO.hash} />
      <WhyUs id={ROUTES.WHY_US.hash} />
      <GetStarted id={ROUTES.GET_STARTED.hash} />
      <Price id={ROUTES.PRICE.hash} />
      <FAQs id={ROUTES.FAQ.hash} />
      <QuotationForm id={ROUTES.QUOTATION_FORM.hash} />
      <OtherProducts id={ROUTES.OTHER_PRODUCTS.hash} />
      <ISO />
      <NoticeForm id={ROUTES.NOTICE_FORM.hash} />
      <Contact />
    </>
  )
}

LandingPage.getLayout = function getLayout(page: React.ReactNode) {
  return <MainLayout>{page}</MainLayout>
}
