import Image from 'next/image'
import { animated, useSprings, useChain, useSpringRef } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '@emotion/react'

import {
  AnimatedAspectRatio,
  DEFAULT_SPRING_CONFIG,
} from '@/lib/springComponent'
import { useBreakpointValue } from '@/lib/hooks'

import { CheckedList } from './CheckedList'

import PlatformImage from '@/images/landing/why-us/platform.png'

type Props = {
  id?: string
}

export function WhyUs({ id }: Props) {
  const theme = useTheme()

  const imageTransform = useBreakpointValue({
    base: 'none !important',
    md: 'scale(1.2) translate3d(-10%, 0, 0)',
  })

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
    onChange: (inView) => {
      springsApi.start((index) => ({
        transform:
          index === 0
            ? inView
              ? 'scale(1.2) translate3d(-10%, 0, 0)'
              : 'scale(1) translate3d(-10%, 0, 0)'
            : inView
            ? 'scale(1)'
            : 'scale(0.9)',
        opacity: inView ? 1 : 0,
      }))
    },
  })

  const springsRef = useSpringRef()
  const trailRef = useSpringRef()

  const [springs, springsApi] = useSprings(2, (index) => ({
    transform:
      index === 0
        ? inView
          ? 'scale(1.2) translate3d(-10%, 0, 0)'
          : 'scale(1) translate3d(-10%, 0, 0)'
        : inView
        ? 'scale(1)'
        : 'scale(0.9)',
    opacity: inView ? 1 : 0,
    ref: springsRef,
    config: DEFAULT_SPRING_CONFIG,
  }))

  useChain([springsRef, trailRef], [0, 1])

  return (
    <section
      css={theme.mq({
        position: 'relative',
        width: '--sizes-full',
        overflowY: 'visible',
      })}
    >
      <div
        id={id}
        ref={ref}
        css={theme.mq({
          ...theme.layerStyles.landingSectionContainer,
          display: 'grid',
          gridTemplateColumns: {
            base: '1fr',
            md: 'repeat(2, 1fr)',
          },
          gridGap: '--space-10',
          alignItems: 'center',
        })}
      >
        <figure
          css={theme.mq({
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            transform: 'translate3d(-40%, -60%, 0)',
            height: '50vw',
            maxHeight: '1000px',
            width: '--sizes-full',
          })}
        >
          <Image
            src="/images/landing/why-us/bg-1.svg"
            alt=""
            layout="fill"
            objectPosition="left"
          />
        </figure>
        <figure
          css={theme.mq({
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translate3d(40%, 60%, 0)',
            height: '20vw',
            maxHeight: '400px',
            width: '--sizes-full',
          })}
        >
          <Image
            src="/images/landing/why-us/bg-2.svg"
            alt=""
            layout="fill"
            objectPosition="right"
          />
        </figure>
        <div css={theme.mq({ position: 'relative' })}>
          <AnimatedAspectRatio
            ratio={597 / 374}
            style={springs[0]}
            sx={{
              position: 'relative',
              boxShadow: '--shadows-xl',
              transform: imageTransform,
            }}
          >
            <Image
              src={PlatformImage}
              alt="platform preview"
              layout="fill"
              objectFit="contain"
              placeholder="blur"
              quality={100}
              sizes="50%"
            />
          </AnimatedAspectRatio>
        </div>
        <animated.div
          style={springs[1]}
          css={theme.mq({
            position: 'relative',
            display: {
              base: 'contents',
              md: 'block',
            },
          })}
        >
          <h2
            css={theme.mq({
              ...theme.textStyles.landingSectionHeader,
              marginBottom: {
                base: '0',
                md: theme.textStyles.landingSectionHeader,
              },
              gridRow: { base: 1, md: 'unset' },
              position: 'relative',
              textAlign: {
                base: 'center',
                md: 'left',
              },
            })}
          >
            ทำไมต้อง Consent Wow
          </h2>
          <CheckedList trailRef={trailRef} inView={inView} />
        </animated.div>
      </div>
    </section>
  )
}
