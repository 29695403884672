import { useTheme } from '@emotion/react'
import { animated } from 'react-spring'

import { ResponsiveCSSObject } from '@/styles/types'

import { useModalContext } from './ModalContext'

type Props = {
  sx?: ResponsiveCSSObject
}

export function ModalOverlay({ sx }: Props) {
  const theme = useTheme()

  const {
    onClose,
    spring: { overlayOpacity },
  } = useModalContext()

  return (
    <animated.div
      style={{
        opacity: overlayOpacity,
        display: overlayOpacity.to((value) =>
          value <= 0.1 ? 'none' : 'block',
        ),
      }}
      css={theme.mq({
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        zIndex: '--zIndices-overlay',
        backgroundColor: '--colors-blackAlpha-400',
        opacity: 0,
        ...sx,
      })}
      onClick={onClose}
    ></animated.div>
  )
}
