import Image from 'next/image'
import { useTheme } from '@emotion/react'

import {
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
} from '@/components/Modal'
import { UseDisclosureReturn } from '@/lib/hooks'

type Props = {
  disclosure: UseDisclosureReturn
}

export function QuotationSuccessFeedbackModal({ disclosure }: Props) {
  const theme = useTheme()

  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalBody
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          textAlign: 'center',
          width: 'calc(100% - 32px)',
          maxWidth: '480px',
        }}
      >
        <ModalCloseButton />
        <Image
          src="/images/landing/modal/quotation-success-feedback.svg"
          alt=""
          width="258px"
          height="161px"
        />
        <p
          css={theme.mq({
            ...theme.textStyles.landingSectionModalHeader,
            marginTop: '--space-8',
          })}
        >
          ขอบคุณที่ส่งข้อความถึงเรา
        </p>
        <p
          css={theme.mq({
            ...theme.textStyles.landingSectionModalSubheader,
          })}
        >
          ข้อความของคุณถูกส่งแล้ว เราจะรีบติดต่อคุณกลับไป
        </p>
      </ModalBody>
    </Modal>
  )
}
