import { Props as ItemProps } from './GetStartedItem'

import Icon1 from '@/images/landing/get-started/1.svg'
import Icon2 from '@/images/landing/get-started/2.svg'
import Icon3 from '@/images/landing/get-started/3.svg'
import Icon4 from '@/images/landing/get-started/4.svg'

export const ITEMS: Omit<ItemProps, 'index'>[] = [
  {
    icon: Icon1,
    title: (
      <>
        สมัครสมาชิก
        <br />
        และสร้างโปรเจกต์
      </>
    ),
    subtitle:
      'สร้างบัญชีผู้ใช้กับเราเพียงไม่กี่ขั้นตอนและสร้างโปรเจ็คจัดการความยินยอมใหม่',
  },
  {
    icon: Icon2,
    title: (
      <>
        ระบุแหล่งความยินยอม
        <br />
        และวัตถุประสงค์
      </>
    ),
    subtitle: 'ระบุแหล่งที่มาของความยินยอมและวัตถุประสงค์ของการขอความยินยอม',
  },
  {
    icon: Icon3,
    title: (
      <>
        เชื่อมต่อหรือ
        <br />
        สร้างแบบฟอร์ม
      </>
    ),
    subtitle: 'เชื่อมต่อกับ Webform ของคุณผ่าน API หรือสร้างแบบฟอร์มใหม่',
  },
  {
    icon: Icon4,
    title: (
      <>
        ดูบันทึกรายการ
        <br />
        ความยินยอม
      </>
    ),
    subtitle: 'รายการบันทึกความยินยอมจะถูกบันทึกเข้ามาบนระบบของเราโดยอัตโนมัติ',
  },
]
