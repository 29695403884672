import { Stack } from '@chakra-ui/react'
import { animated, useTrail, useChain, useSpringRef } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'
import { PriceCard } from './PriceCard'
import { EnterpriseCard } from './Enterprise'
import { planDetails } from './constants'

const AnimatePriceCard = animated(PriceCard)

export const PriceCardContainer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })

  const trailRef = useSpringRef()

  const trails = useTrail(planDetails.length, {
    transform: inView ? 'scale(1)' : 'scale(0.9)',
    opacity: inView ? 1 : 0,
    config: DEFAULT_SPRING_CONFIG,
    ref: trailRef,
  })

  useChain([trailRef], [0, 1])

  return (
    <Stack
      ref={ref}
      spacing="12"
      sx={{ display: { base: 'flex', md: 'none' } }}
    >
      {planDetails.map((plan, index) => {
        return (
          <AnimatePriceCard
            key={index}
            style={trails[index]}
            currentPlan={plan}
          />
        )
      })}
      <EnterpriseCard />
    </Stack>
  )
}
