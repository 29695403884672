import { MdOutlineInfo } from 'react-icons/md'
import { HStack, Icon, Image, Stack, Text, Tooltip } from '@chakra-ui/react'

import { PlanDetail, QuotaDetail } from './type'

export const planDetails: PlanDetail[] = [
  {
    plan: 'small',
    description: (
      <Text fontSize="16px" fontWeight="light">
        เหมาะสำหรับ
        <Text as="span" sx={{ fontWeight: 500 }}>
          ธุรกิจขนาดเล็ก
        </Text>
        หรือ <br />
        ธุรกิจ แบบ B2B <br />
        ที่มีข้อมูลลูกค้าไม่มาก
      </Text>
    ),
    descriptionMobile: (
      <Text fontSize="16px" fontWeight="light">
        เหมาะสำหรับ
        <Text as="span" sx={{ fontWeight: 500 }}>
          ธุรกิจขนาดเล็ก
        </Text>
        หรือ ธุรกิจแบบ B2B ที่มีข้อมูลลูกค้าไม่มาก
      </Text>
    ),
    price: {
      month: 1490,
      year: 16986,
    },
  },
  {
    plan: 'medium',
    description: (
      <Text fontSize="16px" fontWeight="light">
        เหมาะสำหรับ
        <Text as="span" sx={{ fontWeight: 500 }}>
          ธุรกิจขนาดกลาง
        </Text>
        <br />
        รองรับได้ 10,000 <br />
        ความยินยอมต่อเดือน
      </Text>
    ),
    descriptionMobile: (
      <Text fontSize="16px" fontWeight="light">
        เหมาะสำหรับ
        <Text as="span" sx={{ fontWeight: 500 }}>
          ธุรกิจขนาดกลาง
        </Text>
        รองรับได้ 10,000 ความยินยอมต่อเดือน
      </Text>
    ),
    price: {
      month: 4990,
      year: 56886,
    },
  },
  {
    plan: 'large',
    description: (
      <Text fontSize="16px" fontWeight="light">
        เหมาะสำหรับ
        <Text as="span" sx={{ fontWeight: 500 }}>
          ธุรกิจขนาดใหญ่
        </Text>
        ที่มี
        <br />
        ลูกค้าเป็นจำนวนมาก เช่น ธุรกิจ <br />
        e-commerce ธุรกิจสินค้า <br />
        อุปโภคบริโภค ธุรกิจประกัน
      </Text>
    ),
    descriptionMobile: (
      <Text fontSize="16px" fontWeight="light">
        เหมาะสำหรับ
        <Text as="span" sx={{ fontWeight: 500 }}>
          ธุรกิจขนาดใหญ่
        </Text>
        ที่มีลูกค้าเป็นจำนวนมาก เช่น ธุรกิจ e-commerce ธุรกิจสินค้า อุปโภคบริโภค
        ธุรกิจประกัน
      </Text>
    ),
    price: {
      month: 9990,
      year: 112860,
    },
  },
]

export const quotaDetails: QuotaDetail[] = [
  {
    key: 'transaction_quota',
    title: (
      <HStack>
        <Text>บันทึกกิจกรรมความยินยอม</Text>
        <Tooltip
          shouldWrapChildren
          hasArrow
          label="บันทึกกิจกรรมความยินยอมจะนับทุกครั้งที่เจ้าของข้อมูลมีการเปลี่ยนแปลงข้อมูลความยินยอม"
          placement="top"
        >
          <Icon
            as={MdOutlineInfo}
            sx={{ cursor: 'pointer', display: 'block' }}
          />
        </Tooltip>
      </HStack>
    ),
    quota: {
      small: (
        <Stack spacing="0">
          <Text>1,000</Text>
          <Text fontSize="16px" fontWeight="light">
            ความยินยอมต่อเดือน
          </Text>
        </Stack>
      ),
      medium: (
        <Stack spacing="0">
          <Text>10,000</Text>
          <Text fontSize="16px" fontWeight="light">
            ความยินยอมต่อเดือน
          </Text>
        </Stack>
      ),
      large: (
        <Stack spacing="0">
          <Text>100,000</Text>
          <Text fontSize="16px" fontWeight="light">
            ความยินยอมต่อเดือน
          </Text>
        </Stack>
      ),
    },
    detailsMobile: {
      small: (
        <Text fontWeight="light">
          บันทึกกิจกรรมความยินยอมสูงสุด <br />
          <Text as="span" sx={{ fontWeight: 500 }}>
            1,000 ความยินยอม
          </Text>
          &nbsp;ต่อเดือน
        </Text>
      ),
      medium: (
        <Text fontWeight="light">
          บันทึกกิจกรรมความยินยอมสูงสุด <br />
          <Text as="span" sx={{ fontWeight: 500 }}>
            10,000 ความยินยอม
          </Text>
          &nbsp;ต่อเดือน
        </Text>
      ),
      large: (
        <Text fontWeight="light">
          บันทึกกิจกรรมความยินยอมสูงสุด <br />
          <Text as="span" sx={{ fontWeight: 500 }}>
            100,000 ความยินยอม
          </Text>
          &nbsp;ต่อเดือน
        </Text>
      ),
    },
  },
  {
    key: 'project_quota',
    title: (
      <HStack>
        <Text>จำนวนโปรเจกต์สูงสุด</Text>
        <Tooltip
          shouldWrapChildren
          hasArrow
          label="จำนวนโปรเจกต์สูงสุดที่สามารถสร้างได้ โดยในหนึ่งโปรเจกต์สามารถเชื่อมต่อได้หลายแหล่งที่มาของความยินยอม"
          placement="top"
        >
          <Icon
            as={MdOutlineInfo}
            sx={{ cursor: 'pointer', display: 'block' }}
          />
        </Tooltip>
      </HStack>
    ),
    quota: {
      small: 1,
      medium: 100,
      large: 'ไม่จำกัด',
    },
    detailsMobile: {
      small: (
        <Text fontWeight="light">
          จัดการได้&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            1 โปรเจกต์
          </Text>
        </Text>
      ),
      medium: (
        <Text fontWeight="light">
          จัดการได้&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            100 โปรเจกต์
          </Text>
        </Text>
      ),
      large: <Text fontWeight="light">ไม่จำกัดจำนวนโปรเจกต์</Text>,
    },
  },
  {
    key: 'storage_time',
    title: (
      <HStack>
        <Text>ระยะเวลาจัดเก็บบันทึกความยินยอม</Text>
        <Tooltip
          shouldWrapChildren
          hasArrow
          label="ข้อมูลความยินยอมสถานะ “ถอน” ที่มีอายุนานที่สุด จะถูกลบออก"
          placement="top"
        >
          <Icon
            as={MdOutlineInfo}
            sx={{ cursor: 'pointer', display: 'block' }}
          />
        </Tooltip>
      </HStack>
    ),
    quota: {
      small: <Text>1 ปี</Text>,
      medium: <Text>3 ปี</Text>,
      large: <Text>5 ปี</Text>,
    },
    detailsMobile: {
      small: (
        <Text fontWeight="light">
          จัดเก็บบันทึกความยินยอมเป็นเวลา&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            1 ปี
          </Text>
        </Text>
      ),
      medium: (
        <Text fontWeight="light">
          จัดเก็บบันทึกความยินยอมเป็นเวลา&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            3 ปี
          </Text>
        </Text>
      ),
      large: (
        <Text fontWeight="light">
          จัดเก็บบันทึกความยินยอมเป็นเวลา&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            5 ปี
          </Text>
        </Text>
      ),
    },
  },
  {
    key: 'user_quota',
    title: 'จำนวนผู้ใช้งาน',
    quota: {
      small: 1,
      medium: 10,
      large: 50,
    },
    detailsMobile: {
      small: (
        <Text fontWeight="light">
          จัดการได้สูงสุด&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            1 ผู้ใช้งาน
          </Text>
        </Text>
      ),
      medium: (
        <Text fontWeight="light">
          จัดการได้สูงสุด&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            10 ผู้ใช้งาน
          </Text>
        </Text>
      ),
      large: (
        <Text fontWeight="light">
          จัดการได้สูงสุด&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            50 ผู้ใช้งาน
          </Text>
        </Text>
      ),
    },
  },
  {
    key: 'form_quota',
    title: 'สร้างแบบฟอร์มการให้ความยินยอม',
    quota: {
      small: 10,
      medium: 100,
      large: 'ไม่จำกัด',
    },
    detailsMobile: {
      small: (
        <Text fontWeight="light">
          สร้างแบบฟอร์มการให้ความยินยอม <br /> ได้มากถึง &nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            10 แบบฟอร์ม
          </Text>
        </Text>
      ),
      medium: (
        <Text fontWeight="light">
          สร้างแบบฟอร์มการให้ความยินยอม <br /> ได้มากถึง &nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            100 แบบฟอร์ม
          </Text>
        </Text>
      ),
      large: 'สร้างแบบฟอร์มการให้ความยินยอมได้ไม่จำกัดจำนวน',
    },
  },
  {
    key: 'import_quota',
    title: (
      <Text textAlign="start">
        อัปโหลดข้อมูลความยินยอม <br /> จากเครื่องของคุณ
      </Text>
    ),
    quota: {
      small: <Image src="/images/landing/why-us/checked.svg" alt="checkList" />,
      medium: (
        <Image src="/images/landing/why-us/checked.svg" alt="checkList" />
      ),
      large: <Image src="/images/landing/why-us/checked.svg" alt="checkList" />,
    },
    detailsMobile: {
      small: 'อัปโหลดข้อมูลความยินยอมจากเครื่องของคุณ',
      medium: 'อัปโหลดข้อมูลความยินยอมจากเครื่องของคุณ',
      large: 'อัปโหลดข้อมูลความยินยอมจากเครื่องของคุณ',
    },
  },
  {
    key: 'preference_quota',
    title: (
      <Text textAlign="start">
        ศูนย์ตั้งค่าความยินยอมสำหรับ <br /> เจ้าของข้อมูลส่วนบุคคล
      </Text>
    ),
    quota: {
      small: <Image src="/images/landing/why-us/checked.svg" alt="checkList" />,
      medium: (
        <Image src="/images/landing/why-us/checked.svg" alt="checkList" />
      ),
      large: <Image src="/images/landing/why-us/checked.svg" alt="checkList" />,
    },
    detailsMobile: {
      small: 'ศูนย์ตั้งค่าความยินยอมสำหรับเจ้าของข้อมูลส่วนบุคคล',
      medium: 'ศูนย์ตั้งค่าความยินยอมสำหรับเจ้าของข้อมูลส่วนบุคคล',
      large: 'ศูนย์ตั้งค่าความยินยอมสำหรับเจ้าของข้อมูลส่วนบุคคล',
    },
  },
  {
    key: 'support',
    title: 'การให้ความช่วยเหลือ',
    quota: {
      small: <Text>Live Chat</Text>,
      medium: (
        <Stack spacing="0">
          <Text>Account manager</Text>
          <Text fontSize="16px" fontWeight="light">
            (Live chat/อีเมล)
          </Text>
        </Stack>
      ),
      large: (
        <Stack spacing="0" p="6">
          <Text>
            Priority Support <br />
            โดย Account Manager
          </Text>
          <Text fontSize="16px" fontWeight="light">
            (Live chat/อีเมล/โทรศัพท์)
          </Text>
        </Stack>
      ),
    },
    detailsMobile: {
      small: (
        <Text fontWeight="light">
          ระบบให้ความช่วยเหลือแบบ&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            Live chat
          </Text>
        </Text>
      ),
      medium: (
        <Text fontWeight="light">
          ระบบให้ความช่วยเหลือแบบ&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            Account manager (Live chat/อีเมล)
          </Text>
        </Text>
      ),
      large: (
        <Text fontWeight="light">
          ระบบให้ความช่วยเหลือแบบ&nbsp;
          <Text as="span" sx={{ fontWeight: 500 }}>
            Priority Support โดย Account Manager (Live chat/อีเมล/โทรศัพท์)
          </Text>
        </Text>
      ),
    },
  },
]

export const EnterpriseDetail = {
  title: 'enterprise',
  description: (
    <Text fontSize={{ base: '16px', md: '20px' }} fontWeight="light">
      เหมาะสำหรับ
      <Text as="span" sx={{ fontWeight: 500 }}>
        สำหรับองค์กรขนาดใหญ่หรือเอเจนซี่
      </Text>
      ที่ต้องการการปรับแต่งที่มากกว่า
    </Text>
  ),
  details: [
    'ติดตั้งระบบ Consent Wow บนเซิร์ฟเวอร์หรือคลาวด์ของคุณเอง',
    'ใช้ได้ไม่จำกัดกับบริษัทในเครือทั้งหมด',
    'Priority support โดย account manager',
  ],
}
