import { Props as ItemProps } from './InfoItem'

import Icon1 from '@/images/landing/info/1.svg'
import Icon2 from '@/images/landing/info/2.svg'
import Icon3 from '@/images/landing/info/3.svg'

export const ITEMS: ItemProps[] = [
  {
    icon: Icon1,
    title: 'จัดการความยินยอมในที่เดียว',
    subtitle:
      'จัดการคำยินยอมบนเว็บไซต์หรือแอปพลิเคชัน หรือ นำเข้ารายการความยินยอม',
  },
  {
    icon: Icon2,
    title: 'สร้างแบบฟอร์มขอความยินยอม',
    subtitle:
      'สร้างแบบฟอร์มขอความยินยอมที่ใช้งานง่ายบนเว็บไซต์สอดคล้องตาม PDPA ',
  },
  {
    icon: Icon3,
    title: 'บันทึกความยินยอมอัตโนมัติ',
    subtitle:
      'บันทึกรายการความยินยอมอัตโนมัติบนคลาวด์ที่สามารถ export ได้ทุกที่ทุกเวลา',
  },
]
