import { useTheme } from '@emotion/react'
import { animated } from 'react-spring'

import { ResponsiveCSSObject } from '@/styles/types'

import { useModalContext } from './ModalContext'

type Props = {
  children?: React.ReactNode
  sx?: ResponsiveCSSObject
}

export function ModalBody({ children, sx }: Props) {
  const theme = useTheme()

  const {
    spring: { opacity, transform },
  } = useModalContext()

  return (
    <animated.div
      style={{
        opacity,
        transform,
        display: opacity.to((value) => (value <= 0.1 ? 'none' : 'block')),
      }}
      css={theme.mq({
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, calc(-50% + 32px), 0) scale(0.95)',
        zIndex: '--zIndices-modal',
        backgroundColor: 'white',
        paddingTop: '--space-10',
        paddingBottom: '--space-6',
        paddingRight: '--space-6',
        paddingLeft: '--space-6',
        borderRadius: '--radii-2xl',
        boxShadow: '--shadows-xl',
        minWidth: '320px',
        ...sx,
      })}
    >
      {children}
    </animated.div>
  )
}
