import { Center } from '@chakra-ui/react'

export const DiscountTag = () => {
  return (
    <Center
      sx={{
        w: { base: '74px', sm: '96px', md: '117px' },
        h: { base: '26px', sm: '38px', md: '50px' },
        ml: '6',
        position: 'relative',
        bg: 'secondary.500',
        borderRadius: { base: '4px', md: '8px' },
        color: 'white',
        fontSize: { base: '12px', sm: '16px', md: '20px' },
        fontWeight: 500,
        zIndex: 3,
        _before: {
          content: '" "',
          w: { base: '8px', sm: '10px', md: '24px' },
          h: { base: '8px', sm: '10px', md: '24px' },
          top: '50%',
          left: 0,
          transform: {
            base: 'translate(-3px, -50%) rotate(45deg)',
            md: 'translate(-5px, -50%) rotate(45deg)',
          },
          bg: 'secondary.500',
          position: 'absolute',
          zIndex: -1,
        },
      }}
    >
      ประหยัด 5%
    </Center>
  )
}
