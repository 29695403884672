import { forwardRef } from 'react'
import { Box, Grid, Text, HStack, Image } from '@chakra-ui/react'

import { Button } from '@/components/Button'
import { Link } from '@/lib/link'
import { DiscountTag } from './DiscountTag'
import { PlanDetail } from './type'
import { quotaDetails } from './constants'

type PriceCardProps = {
  currentPlan: PlanDetail
}

export const PriceCard = forwardRef(
  (props: PriceCardProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { currentPlan } = props
    const isEvenQuotas = quotaDetails.length % 2 === 0

    return (
      <Grid
        ref={ref}
        sx={{
          gridTemplateColumns: { xs: '320px', sm: '420px' },
          justifyContent: { xs: 'center' },
        }}
      >
        <Box
          sx={{
            bg: 'primary.500',
            borderTopRadius: '16px',
            color: 'white',
            p: 4,
            textAlign: 'start',
            fontWeight: 500,
          }}
        >
          <Text sx={{ textTransform: 'uppercase', fontSize: { sm: '1.5rem' } }}>
            {currentPlan.plan}
          </Text>
          <Text sx={{ fontSize: { base: '28px', sm: '40px' } }}>
            {currentPlan.price.month.toLocaleString()}&nbsp;
            <Text
              as="span"
              sx={{
                fontSize: { base: '16px', sm: '1.5rem' },
                fontWeight: 'light',
              }}
            >
              บาท/เดือน
            </Text>
          </Text>
          <HStack spacing="4">
            <Text sx={{ fontSize: { sm: '1.5rem' } }}>
              หรือ {currentPlan.price.year.toLocaleString()} บาทต่อปี
            </Text>
            <DiscountTag />
          </HStack>
        </Box>
        <ListQuota
          bgLight={isEvenQuotas}
          description={currentPlan.descriptionMobile}
        />
        {quotaDetails.map((quota, index) => {
          const isEven = index % 2 === 0
          const bg = isEvenQuotas ? !isEven : isEven
          return (
            <ListQuota
              bgLight={bg}
              key={index}
              description={quota.detailsMobile[currentPlan.plan]}
            />
          )
        })}
        <Box
          sx={{
            bg: 'primary.100',
            borderBottomRadius: '16px',
            p: 6,
          }}
        >
          <Link to="APP_SIGN_UP">
            <Button
              sx={{
                fontSize: { base: '16px', sm: '1.5rem' },
                fontWeight: 500,
                width: '--sizes-full',
                height: { base: '40px', sm: '50px' },
              }}
            >
              เลือกแพ็กเกจนี้
            </Button>
          </Link>
        </Box>
      </Grid>
    )
  },
)

type ListQuotaProps = {
  bgLight: boolean
  description: string | number | JSX.Element
}

export const ListQuota = (props: ListQuotaProps) => {
  const { bgLight, description } = props
  return (
    <HStack
      spacing={2}
      sx={{
        bg: bgLight ? 'primary.50' : 'primary.100',
        p: 4,
        alignItems: 'start',
        textAlign: 'start',
      }}
    >
      <Image src="/images/landing/why-us/checked.svg" alt="checkList" />
      <Box sx={{ fontWeight: 'light' }}>{description}</Box>
    </HStack>
  )
}
