import { Fragment, ReactNode, useMemo } from 'react'
import { Box, Grid, Flex, Text } from '@chakra-ui/react'

import { Button } from '@/components/Button'
import { Link } from '@/lib/link'
import { planDetails, quotaDetails } from './constants'
import { renderPackageImage } from './helper'
import { Recurring } from './type'

type Props = {
  recurring: Recurring
}

export const PriceTable = (props: Props) => {
  const { recurring } = props

  const isMonthRecurring = useMemo(() => {
    return recurring === 'month'
  }, [recurring])

  return (
    <Grid sx={{ gridTemplateColumns: '354px repeat(3, 1fr)', gap: 1 }}>
      <Box />
      {planDetails.map((item, index) => {
        return (
          <Flex
            key={item.plan}
            sx={{
              bg: 'primary.50',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              px: 5,
              py: 6,
              borderTopLeftRadius: index === 0 && '16px',
              borderTopRightRadius: index + 1 === planDetails.length && '16px',
            }}
          >
            <Flex sx={{ h: '120px', alignItems: 'end' }}>
              {renderPackageImage(item.plan)}
            </Flex>

            <Box sx={{ textAlign: 'start', my: 5 }}>
              <Text
                sx={{
                  fontSize: '32px',
                  fontWeight: 500,
                  textTransform: 'capitalize',
                  mb: 1,
                }}
              >
                {item.plan}
              </Text>
              {item.description}
            </Box>
            <Text sx={{ color: 'primary.500', alignSelf: 'end', mt: 'auto' }}>
              <Text as="span" sx={{ fontSize: '40px', fontWeight: 600 }}>
                {isMonthRecurring
                  ? item.price.month.toLocaleString()
                  : item.price.year.toLocaleString()}
                .-
              </Text>
              &nbsp;/{isMonthRecurring ? 'เดือน' : 'ปี'}
            </Text>
          </Flex>
        )
      })}
      {quotaDetails.map((item, index) => {
        const isEven = index % 2 === 0
        return (
          <Fragment key={item.key}>
            <Flex
              sx={{
                color: 'white',
                alignItems: 'center',
                bg: isEven ? 'primary.500' : 'primary.600',
                borderTopLeftRadius: index === 0 && '16px',
                borderBottomLeftRadius:
                  index + 1 === quotaDetails.length && '16px',
                fontSize: '20px',
                fontWeight: 500,
                pl: 6,
              }}
            >
              {item.title}
            </Flex>
            {Object.keys(item.quota).map((key, index) => {
              return (
                <QuotaBox key={index} isEven={isEven}>
                  {item.quota[key]}
                </QuotaBox>
              )
            })}
          </Fragment>
        )
      })}
      <Box />
      {planDetails.map((item, index) => {
        const isOdd = quotaDetails.length % 2 === 0
        return (
          <Box
            key={index}
            sx={{
              p: 6,
              bg: isOdd ? 'primary.100' : 'primary.50',
              borderBottomRadius: '16px',
            }}
          >
            <Link to="APP_SIGN_UP">
              <Button
                sx={{
                  fontSize: '20px',
                  fontWeight: 500,
                  width: '--sizes-full',
                  height: '48px',
                }}
              >
                เลือกแพ็กเกจนี้
              </Button>
            </Link>
          </Box>
        )
      })}
    </Grid>
  )
}

type QuotaBoxProps = {
  children: ReactNode
  isEven: boolean
}

const QuotaBox = (props: QuotaBoxProps) => {
  const { children, isEven } = props
  return (
    <Flex
      sx={{
        bg: isEven ? 'primary.100' : 'primary.50',
        minH: '78px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
      }}
    >
      {children}
    </Flex>
  )
}
