import { Image } from '@chakra-ui/react'

import { PlanType } from './type'

import SmallPackImage from '@/images/packages/small_pk.svg'
import MediumPackImage from '@/images/packages/medium_pk.svg'
import LargePackImage from '@/images/packages/large_pk.svg'
import EnterprisePackImage from '@/images/packages/enterprise_pk.svg'

export const renderPackageImage = (plan: PlanType) => {
  switch (plan) {
    case 'small':
      return <Image src={SmallPackImage} alt="smallPackage" />
    case 'medium':
      return <Image src={MediumPackImage} alt="mediumPackage" />
    case 'large':
      return <Image src={LargePackImage} alt="largelPackage" />
    case 'enterprise':
      return <Image src={EnterprisePackImage} alt="enterprisePackage" />
    default:
      return
  }
}
