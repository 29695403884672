import { useTheme } from '@emotion/react'

import { ResponsiveCSSObject } from '@/styles/types'

import { FormControlContextProvider } from './FormControlContext'

type Props = {
  children: React.ReactNode
  requiredMark?: string
  isRequired?: boolean
  label?: string
  hintText?: string
  errorText?: string
  name?: string
  sx?: ResponsiveCSSObject
}

export function FormControl(props: Props) {
  const theme = useTheme()

  const {
    isRequired,
    requiredMark = '*',
    children,
    label,
    hintText,
    errorText,
    name,
    sx,
  } = props

  return (
    <FormControlContextProvider isRequired={isRequired}>
      <div
        css={theme.mq({
          display: 'flex',
          flexFlow: 'column nowrap',
          fontSize: '--fontSizes-md',
          fontWeight: '--fontWeights-normal',
          ...sx,
        })}
      >
        {label && (
          <label
            htmlFor={name}
            css={theme.mq({
              marginBottom: '--space-2',
              alignSelf: 'flex-start',
            })}
          >
            {label}{' '}
            {isRequired && (
              <span
                css={theme.mq({
                  color: '--colors-red-500',
                })}
              >
                {requiredMark}
              </span>
            )}
          </label>
        )}
        {children}
        {hintText && !errorText && (
          <p
            css={theme.mq({
              marginTop: '--space-3',
              fontWeight: '--fontWeights-normal',
              fontSize: { base: '--fontSizes-xs', sm: '--fontSizes-sm' },
              color: '--colors-gray-500',
              alignSelf: 'flex-start',
            })}
          >
            {hintText}
          </p>
        )}
        {errorText && (
          <p
            css={theme.mq({
              marginTop: '--space-3',
              fontWeight: '--fontWeights-normal',
              fontSize: { base: '--fontSizes-xs', sm: '--fontSizes-sm' },
              color: '--colors-red-500',
              alignSelf: 'flex-start',
            })}
          >
            {errorText}
          </p>
        )}
      </div>
    </FormControlContextProvider>
  )
}
