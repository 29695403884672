import { forwardRef } from 'react'
import { useTheme, Theme } from '@emotion/react'

import { useFormControlContext } from '@/components/FormControl/FormControlContext'
import { ResponsiveCSSObject, Size } from '@/styles/types'

import { variants, sizes, baseStyle } from '@/components/Input/theme'

type Variant = keyof typeof variants

type Props = {
  colorScheme?: keyof Theme['colors']
  isRequired?: boolean
  isInvalid?: boolean
  isReadOnly?: boolean
  isDisabled?: boolean
  variant?: Variant
  sizing?: Size
  sx?: ResponsiveCSSObject
} & React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

export const Textarea = forwardRef(function Textarea(
  {
    colorScheme = 'primary',
    isInvalid = false,
    isRequired = false,
    isReadOnly = false,
    isDisabled = false,
    variant = 'outline',
    sizing = 'md',
    sx,
    ...rest
  }: Props,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const theme = useTheme()
  const formControlContext = useFormControlContext()

  return (
    <textarea
      ref={ref}
      required={isRequired || formControlContext.isRequired}
      readOnly={isReadOnly}
      disabled={isDisabled}
      {...rest}
      css={theme.mq({
        ...baseStyle,
        minHeight: '80px',
        paddingTop: '--space-2',
        paddingBottom: '--space-2',
        ...sizes[sizing],
        ...variants[variant](colorScheme),
        ...(!isInvalid && { '&:invalid': {} }),
        ...sx,
      })}
    ></textarea>
  )
})
