import Image from 'next/image'
import { useTheme } from '@emotion/react'

type Props = {
  id?: string
}

export function ISO({ id }: Props) {
  const theme = useTheme()

  return (
    <section
      id={id}
      css={theme.mq({
        ...theme.layerStyles.landingSectionContainer,
      })}
    >
      <h2
        css={theme.mq({
          ...theme.textStyles.landingSectionHeader,
          marginBottom: '--space-8',
        })}
      >
        Certified
      </h2>
      <Image
        src="/images/landing/iso.svg"
        alt="iso"
        width="270px"
        height="88px"
      />
      <p
        css={theme.mq({
          marginTop: { base: '--space-4', xs: '--space-8' },
          fontSize: { base: '--fontSizes-md', sm: '--fontSizes-2xl' },
          whiteSpace: { base: 'unset', md: 'pre' },
        })}
      >
        Consent Wow ได้รับมาตรฐาน{' '}
        <span css={theme.mq({ color: '--colors-primary-500' })}>
          ISO 27001 (Information Security)
        </span>
        <br />
        และมาตรฐาน{' '}
        <span css={theme.mq({ color: '--colors-primary-500' })}>
          ISO 27701 (Privacy Information)
        </span>
      </p>
    </section>
  )
}
