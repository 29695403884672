import { useTheme } from '@emotion/react'
import Image from 'next/image'

import env from '@/lib/env'
import { Button } from '@/components/Button'
import Icon2 from '@/images/landing/hero/annoucement.svg'

type Props = {
  id?: string
}

export function NoticeForm({ id }: Props) {
  const theme = useTheme()

  return (
    <section
      css={theme.mq({
        position: 'relative',
        width: '--sizes-full',
        paddingTop: theme.layerStyles.landingSectionContainer.paddingTop,
        paddingBottom: theme.layerStyles.landingSectionContainer.paddingBottom,
        paddingLeft: theme.layerStyles.landingSectionContainer.paddingLeft,
        paddingRight: theme.layerStyles.landingSectionContainer.paddingRight,
      })}
    >
      <div
        css={theme.mq({
          position: 'absolute',
          width: '--sizes-full',
          top: 0,
          left: 0,
          right: 0,
          backgroundImage: 'url(/images/landing/header-bg.svg)',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
          backgroundSize: '50px',
          height: '50%',
        })}
      ></div>
      <div
        css={theme.mq({
          position: 'absolute',
          width: '--sizes-full',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '--colors-tertiary-900',
          height: '50%',
        })}
      ></div>
      <div
        id={id}
        css={theme.mq({
          ...theme.layerStyles.landingSectionContainer,
          maxWidth: {
            base: '--sizes-full',
            sm: '--sizes-container-sm',
            md: '1026px',
          },
          minWidth: { base: '100%', xs: '320px' },
          backgroundColor: '--colors-primary-100',
          borderRadius: '--radii-3xl',
          position: 'relative',
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        <div
          css={theme.mq({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: { base: '--space-6', sm: '--space-12', md: '--space-16' },
            paddingLeft: {
              base: '--space-6',
              sm: '--space-12',
              md: '--space-40',
            },
            paddingRight: {
              base: '--space-6',
              sm: '--space-12',
              md: 0,
            },
          })}
        >
          <h2
            css={theme.mq({
              ...theme.textStyles.landingSectionHeader,
              fontWeight: '--fontWeights-normal',
              marginBottom: 0,
              br: {
                display: {
                  base: 'block',
                  md: 'none',
                },
              },
            })}
          >
            <span
              css={theme.mq({
                color: '--colors-primary-500',
                fontWeight: '--fontWeights-semibold',
              })}
            >
              Consent Wow
            </span>{' '}
            <br />
            เปิดให้ใช้งานได้แล้ว
          </h2>
          <a
            href={`${env.PLATFORM_URL}/sign-up`}
            target="_blank"
            rel="noreferrer"
            css={theme.mq({
              width: { base: '--sizes-full', md: '547px' },
              marginTop: '--space-6',
            })}
          >
            <Button
              sx={{
                fontSize: {
                  base: '--fontSizes-md',
                  xs: '20px',
                },
                height: {
                  base: '--space-10',
                  xs: '--space-16',
                },
                width: '--sizes-full',
              }}
            >
              เริ่มใช้งาน
            </Button>
          </a>
          <div
            css={theme.mq({
              display: {
                base: 'none',
                md: 'undefined',
              },
              position: 'absolute',
              left: '-145px',
              top: '-125px',
            })}
          >
            <Image src={Icon2} alt="" width="403px" height="464px" />
          </div>
        </div>
      </div>
    </section>
  )
}
