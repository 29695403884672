import Image from 'next/image'
import { useState } from 'react'
import { Flex, Switch, Stack, Text } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { Recurring } from './type'

import { Enterprise } from './Enterprise'
import { DiscountTag } from './DiscountTag'
import { PriceTable } from './PriceTable'
import { PriceCardContainer } from './PriceCardContainer'

type Props = {
  id?: string
}

export const Price = ({ id }: Props) => {
  const theme = useTheme()
  const [currentRecurring, setCurrentRecurring] = useState<Recurring>('month')

  return (
    <section
      id={id}
      css={theme.mq({
        position: 'relative',
        width: '--sizes-full',
      })}
    >
      <div
        css={theme.mq({
          position: 'absolute',
          width: '--sizes-full',
          height: '240px',
          top: 0,
          left: 0,
          bottom: 0,
          transform: { base: 'translate3d(0, -10%, 0)', sm: 'none' },
          backgroundImage: 'url(/images/landing/header-bg.svg)',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
          backgroundSize: '50px',
        })}
      ></div>
      <div
        css={theme.mq({
          ...theme.layerStyles.landingSectionContainer,
          position: 'relative',
        })}
      >
        <figure
          css={theme.mq({
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            transform: 'translate3d(-65%, -40%, 0)',
            height: '50vw',
            maxHeight: '1000px',
            width: '--sizes-full',
            zIndex: -1,
          })}
        >
          <Image
            src="/images/landing/why-us/bg-1.svg"
            alt=""
            layout="fill"
            objectPosition="left"
          />
        </figure>
        <figure
          css={theme.mq({
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translate3d(70%, 60%, 0)',
            height: '20vw',
            maxHeight: '400px',
            width: '--sizes-full',
            zIndex: -1,
          })}
        >
          <Image
            src="/images/landing/why-us/bg-2.svg"
            alt=""
            layout="fill"
            objectPosition="right"
          />
        </figure>
        <h2
          css={theme.mq({
            ...theme.textStyles.landingSectionHeader,
            marginBottom: 0,
            backgroundColor: 'white',
            alignSelf: 'center',
            padding: '--space-2',
            paddingBottom: '--space-2',
            paddingLeft: {
              base: '--space-2',
              md: '--space-10',
            },
            paddingRight: {
              base: '--space-2',
              md: '--space-10',
            },
            width: 'fit-content',
            margin: 'auto',
          })}
        >
          ราคาและแพ็กเกจ
        </h2>
        <p
          css={theme.mq({
            ...theme.textStyles.landingSectionSubheader,
            marginBottom: 0,
            paddingBottom:
              theme.textStyles.landingSectionSubheader.marginBottom,
            backgroundColor: 'white',
            alignSelf: 'center',
            paddingTop: '--space-2',
          })}
        >
          รองรับได้มากกว่ากับราคาสุดคุ้ม (รวมภาษีมูลค่าเพิ่ม 7%)
        </p>
        <Stack spacing="12" display={{ base: 'none', md: 'flex' }}>
          <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Text
              sx={{ color: 'primary.600', fontSize: '20px', fontWeight: 500 }}
            >
              รายเดือน
            </Text>
            <Switch
              mx="6"
              onChange={(e) => {
                if (e.target.checked) return setCurrentRecurring('year')
                setCurrentRecurring('month')
              }}
            />
            <Text
              sx={{ color: 'primary.600', fontSize: '20px', fontWeight: 500 }}
            >
              รายปี
            </Text>
            <DiscountTag />
          </Flex>
          <PriceTable recurring={currentRecurring} />
          <Enterprise />
        </Stack>

        <PriceCardContainer />
      </div>
    </section>
  )
}
