import { useTheme } from '@emotion/react'

import { Link } from '@/lib/link'
import { LINKS } from './constants'

export function Links() {
  const theme = useTheme()

  return (
    <section
      css={theme.mq({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '--space-6',
        textAlign: { base: 'center', sm: 'left' },
      })}
    >
      <p
        css={theme.mq({
          fontSize: '--fontSizes-xl',
          color: '--colors-primary-250',
          fontWeight: '--fontWeights-medium',
        })}
      >
        แผนผังเว็บไซต์
      </p>
      {LINKS.map(({ label, ...url }, index) => {
        return (
          <Link key={`${index}`} {...url}>
            <span
              css={theme.mq({
                ...theme.layerStyles.actionable,
                fontWeight: '--fontWeights-medium',
                fontSize: '--fontSizes-md',
              })}
            >
              {label}
            </span>
          </Link>
        )
      })}
    </section>
  )
}
