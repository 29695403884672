import { useEffect } from 'react'
import { useSpring, SpringValue } from 'react-spring'
import { createCtx } from '@/lib/utils'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

export type ModalContext = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  spring: Dict<SpringValue>
}

export type ModalContextProviderProps = {
  children: React.ReactNode
} & Omit<ModalContext, 'spring'>

const modalContext = createCtx<ModalContext>()
const Provider = modalContext[1]
export const useModalContext: () => ModalContext = modalContext[0]

export const ModalContextProvider = ({
  children,
  isOpen,
  onOpen,
  onClose,
}: ModalContextProviderProps): React.ReactElement => {
  const spring = useSpring({
    overlayOpacity: isOpen ? 1 : 0,
    opacity: isOpen ? 1 : 0,
    transform: isOpen
      ? 'translate3d(-50%, calc(-50% + 0px), 0px) scale(1)'
      : 'translate3d(-50%, calc(-50% + 32px), 0px) scale(0.95)',
    config: DEFAULT_SPRING_CONFIG,
  })

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return (
    <Provider value={{ isOpen, onOpen, onClose, spring }}>{children}</Provider>
  )
}
