import { useTheme } from '@emotion/react'
import { animated, useTrail, SpringRef } from 'react-spring'

import { CHECK_LIST } from './constants'
import { Checked } from './Checked'
import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

const AnimatedChecked = animated(Checked)

type Props = {
  inView?: boolean
  trailRef?: SpringRef
}

export function CheckedList({ trailRef, inView = true }: Props) {
  const theme = useTheme()

  const trails = useTrail(CHECK_LIST.length, {
    transform: inView ? 'scale(1)' : 'scale(0.9)',
    opacity: inView ? 1 : 0,
    config: DEFAULT_SPRING_CONFIG,
    ref: trailRef,
  })

  return (
    <div
      css={theme.mq({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '--space-6',
        alignItems: 'center',
        position: 'relative',
      })}
    >
      {CHECK_LIST.map((item, index) => {
        return (
          <AnimatedChecked key={`${index}`} style={trails[index]} {...item} />
        )
      })}
    </div>
  )
}
