import { useState, useRef, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { FiChevronRight } from 'react-icons/fi'
import { useSpring, animated } from 'react-spring'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'
import { useWindowResizeEffect, useBreakpointValue } from '@/lib/hooks'

const AnimatedRightIcon = animated(FiChevronRight)

export type Props = {
  title: React.ReactNode
  children: React.ReactNode
  defaultIsOpen?: boolean
}

export function Accordion({ title, children, defaultIsOpen = false }: Props) {
  const theme = useTheme()
  const contentRef = useRef<HTMLParagraphElement>(null)

  const [isOpen, setOpen] = useState(defaultIsOpen)
  const [{ opacity, maxHeight, transform }, springApi] = useSpring(() => {
    return {
      to: {
        opacity: 0,
        maxHeight: 0,
        transform: 'rotate(0deg)',
      },
      config: DEFAULT_SPRING_CONFIG,
    }
  })

  useWindowResizeEffect(() => {
    if (!contentRef.current) return

    const { height } = contentRef.current.getBoundingClientRect()
    springApi.start({
      maxHeight: isOpen ? height : 0,
      immediate: true,
    })
  })

  const iconSize = useBreakpointValue({
    base: '24px',
    sm: '32px',
  })

  useEffect(() => {
    if (!contentRef.current || !defaultIsOpen) return

    const { height } = contentRef.current.getBoundingClientRect()

    springApi.start({
      maxHeight: height,
      immediate: true,
      transform: 'rotate(90deg)',
    })
  }, [])

  return (
    <div
      css={theme.mq({
        border: '1px solid',
        borderColor: '--colors-primary-500',
        borderRadius: { base: '--radii-lg', sm: '--radii-2xl' },
        cursor: 'pointer',
        backgroundColor: 'transparent',
        transitionProperty: '--transition-property-common',
        transitionDuration: '--transition-duration-normal',
        transitionTimingFunction: '--transition-easing-ease-in-out',
        textAlign: 'left',
        padding: {
          base: '--space-4',
          sm: '--space-6',
        },
        '&:hover': {
          backgroundColor: '--colors-primary-50',
        },
      })}
      onClick={() => {
        if (!contentRef.current) return

        const { height } = contentRef.current.getBoundingClientRect()
        setOpen(!isOpen)
        springApi.start({
          maxHeight: !isOpen ? height : 0,
          opacity: !isOpen ? 1 : 0,
          transform: !isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
        })
      }}
    >
      <animated.div
        css={theme.mq({
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'flex-start',
          color: isOpen ? '--colors-primary-500' : 'inherit',
        })}
      >
        <p
          css={theme.mq({
            flex: '1 1 100%',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            transitionProperty: '--transition-property-common',
            transitionDuration: '--transition-duration-normal',
            transitionTimingFunction: '--transition-easing-ease-in-out',
            fontSize: {
              base: '--fontSizes-md',
              sm: '--fontSizes-xl',
            },
          })}
        >
          {title}
        </p>
        <figure
          css={theme.mq({
            flex: { base: '0 0 24px', sm: '0 0 32px' },
            height: { base: '--space-6', sm: '--space-8' },
            marginLeft: '--space-2',
          })}
        >
          <AnimatedRightIcon
            size={iconSize}
            style={{ transform }}
            css={{
              position: 'absolute',
              color: 'currentColor',
            }}
          />
        </figure>
      </animated.div>
      <animated.div
        style={{ opacity, maxHeight }}
        css={theme.mq({
          overflowY: 'hidden',
        })}
      >
        <div
          ref={contentRef}
          css={theme.mq({
            paddingTop: '--space-6',
            flex: '1 1 100%',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            fontSize: {
              base: '--fontSizes-sm',
              sm: '--fontSizes-md',
              md: '--fontSizes-xl',
            },
            fontWeight: '--fontWeights-light',
            ul: {
              marginLeft: '--space-8',
              marginTop: '--space-2',
              listStylePosition: 'outside',
              listStyleType: 'disc',
            },
          })}
        >
          {children}
        </div>
      </animated.div>
    </div>
  )
}
