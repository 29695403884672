import { Box, Grid, HStack, Image, Text } from '@chakra-ui/react'

import { Button } from '@/components/Button'
import { ListQuota } from './PriceCard'
import { EnterpriseDetail } from './constants'
import { Link } from '@/lib/link'

import { renderPackageImage } from './helper'

export const Enterprise = () => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: '106px 1fr 125px',
        bg: 'primary.50',
        borderRadius: '16px',
        p: 8,
        mt: 14,
      }}
    >
      {renderPackageImage('enterprise')}
      <Box sx={{ px: 12, textAlign: 'start' }}>
        <Text
          sx={{
            textTransform: 'capitalize',
            fontSize: '32px',
            lineHeight: '32px',
            fontWeight: 500,
          }}
        >
          {EnterpriseDetail.title}
        </Text>
        <Box mt="2">{EnterpriseDetail.description}</Box>
        {EnterpriseDetail.details.map((detail, index) => {
          return (
            <HStack key={index} spacing="2" mt="2">
              <Image src="/images/landing/why-us/checked.svg" alt="checkList" />
              <Text fontWeight="light">{detail}</Text>
            </HStack>
          )
        })}
      </Box>
      <Box sx={{ w: 'full', h: '48px', mt: 'auto' }}>
        <Link to="QUOTATION_FORM">
          <Button
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              width: '--sizes-full',
              height: '--sizes-full',
            }}
          >
            ติดต่อเรา
          </Button>
        </Link>
      </Box>
    </Grid>
  )
}

export const EnterpriseCard = () => {
  const isEvenDetails = EnterpriseDetail.details.length % 2 === 0
  return (
    <Grid
      sx={{
        gridTemplateColumns: { xs: '320px', sm: '420px' },
        justifyContent: { xs: 'center' },
      }}
    >
      <Box
        sx={{
          bg: 'primary.500',
          borderTopRadius: '16px',
          color: 'white',
          p: 4,
          textAlign: 'start',
          fontWeight: 500,
          fontSize: { sm: '1.5rem' },
          textTransform: 'uppercase',
        }}
      >
        {EnterpriseDetail.title}
      </Box>
      <ListQuota
        bgLight={isEvenDetails}
        description={EnterpriseDetail.description}
      />
      {EnterpriseDetail.details.map((detail, index) => {
        const isEven = index % 2 === 0
        const bg = isEvenDetails ? !isEven : isEven
        return <ListQuota key={index} bgLight={bg} description={detail} />
      })}
      <Box
        sx={{
          bg: 'primary.100',
          borderBottomRadius: '16px',
          p: 6,
        }}
      >
        <Link to="QUOTATION_FORM">
          <Button
            sx={{
              fontSize: { base: '16px', sm: '1.5rem' },
              fontWeight: 500,
              width: '--sizes-full',
              height: { base: '40px', sm: '50px' },
            }}
          >
            ติดต่อเรา
          </Button>
        </Link>
      </Box>
    </Grid>
  )
}
