import { ResponsiveCSSObject, Size } from '@/styles/types'

export const sizes: Partial<Record<Size, ResponsiveCSSObject>> = {
  xl: {
    control: {
      height: '--space-7',
      width: '--space-7',
      borderRadius: '--radii-lg',
    },
    label: { fontSize: '--fontSizes-xl' },
  },
  lg: {
    control: {
      height: '--space-6',
      width: '--space-6',
      borderRadius: '--radii-md',
    },
    label: { fontSize: '--fontSizes-lg' },
  },
  md: {
    control: {
      height: '--space-5',
      width: '--space-5',
      borderRadius: '--radii-sm',
    },
    label: { fontSize: '--fontSizes-md' },
  },
  sm: {
    control: {
      height: '--space-4',
      width: '--space-4',
      borderRadius: '--radii-sm',
    },
    label: { fontSize: '--fontSizes-sm' },
  },
  xs: {
    control: {
      height: '--space-3',
      width: '--space-3',
      borderRadius: '--radii-xs',
    },
    label: { fontSize: '--fontSizes-xs' },
  },
}
