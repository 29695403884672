import { forwardRef } from 'react'
import { useTheme, Theme } from '@emotion/react'
import PuffLoader from 'react-spinners/PuffLoader'

import { ResponsiveCSSObject, Size } from '@/styles/types'

import { variants, sizes } from './theme'

type Variant = keyof typeof variants

type Props = {
  isLoading?: boolean
  spinner?: React.ReactNode
  loadingText?: string
  isDisabled?: boolean
  colorScheme?: keyof Theme['colors']
  variant?: Variant
  sizing?: Size
  sx?: ResponsiveCSSObject
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button = forwardRef(function Button(
  {
    isLoading = false,
    spinner,
    loadingText = '',
    isDisabled = false,
    colorScheme = 'primary',
    variant = 'solid',
    sizing = 'md',
    sx,
    children,
    ...rest
  }: Props,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const theme = useTheme()

  const _spinner = spinner ?? <PuffLoader size="1.5em" color="currentColor" />

  return (
    <button
      disabled={isDisabled || isLoading}
      ref={ref}
      {...rest}
      css={theme.mq({
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: isLoading ? 'not-allowed' : 'pointer',
        transitionProperty: '--transition-property-common',
        transitionDuration: '--transition-duration-normal',
        transitionTimingFunction: '--transition-easing-ease-in-out',
        borderRadius: '--radii-md',
        overflow: 'hidden',
        position: 'relative',
        '&:disabled': {
          opacity: 0.5,
          cursor: 'not-allowed !important',
        },
        ...variants[variant](colorScheme),
        ...sizes[sizing],
        ...sx,
      })}
    >
      <span
        css={theme.mq({
          display: isLoading ? 'inline-flex' : 'none',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        {_spinner}
        <span
          css={theme.mq({
            marginLeft: '--space-2',
          })}
        >
          {loadingText}
        </span>
      </span>
      <span
        css={theme.mq({
          visibility: isLoading ? 'hidden' : 'visible',
        })}
      >
        {children}
      </span>
    </button>
  )
})
