import { createCtxSafe } from '@/lib/utils'

export type FormControlContext = {
  isRequired?: boolean
}

export type FormControlContextProviderProps = {
  children: React.ReactNode
} & FormControlContext

const formControlContext = createCtxSafe<FormControlContext>()
const Provider = formControlContext[1]
export const useFormControlContext: () => FormControlContext =
  formControlContext[0]

export const FormControlContextProvider = ({
  children,
  isRequired,
}: FormControlContextProviderProps): React.ReactElement => {
  return <Provider value={{ isRequired }}>{children}</Provider>
}
