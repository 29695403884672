import { forwardRef } from 'react'
import Image from 'next/image'
import { useTheme } from '@emotion/react'

import { ExternalLink } from '@/components/ExternalLink'
import { transparentize } from '@/lib/utils/colors'

import { ResponsiveCSSObject } from '@/styles/types'

export type Props = {
  primaryColor: string
  secondaryColor?: string
  bgImage: StaticImageData | string
  logoImage: StaticImageData | string
  title: string
  href: string
  subtitle: React.ReactNode
  sx?: ResponsiveCSSObject
}

export const OtherProductsItem = forwardRef(function OtherProductsItem(
  {
    primaryColor,
    secondaryColor,
    bgImage,
    logoImage,
    subtitle,
    title,
    href,
    sx,
  }: Props,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const theme = useTheme()

  const opaquePrimaryColor = transparentize(primaryColor, 0.2)

  return (
    <ExternalLink
      href={href}
      ref={ref}
      sx={{
        borderRadius: { base: '--radii-2xl', sm: '--radii-3xl' },
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'left',
        backgroundColor: primaryColor,
        backgroundImage:
          secondaryColor &&
          `linear-gradient(315deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        transitionProperty: 'box-shadow',
        transitionDuration: '--transition-duration-slow',
        transitionTimingFunction: '--transition-easing-ease-in-out',
        boxShadow: `0px 0px 0px 0px ${opaquePrimaryColor}`,
        '&:hover': {
          boxShadow: `10px 10px 0px 0px ${opaquePrimaryColor}`,
        },
        height: {
          base: '120px',
          sm: '160px',
        },
        ...sx,
      }}
    >
      <div
        css={theme.mq({
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: { base: '50%', sm: '--sizes-full' },
        })}
      ></div>
      <div
        css={theme.mq({
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          height: '--sizes-full',
          padding: { base: '--space-4', sm: '--space-6' },
          width: '60%',
        })}
      >
        <figure
          css={theme.mq({
            width: '--sizes-full',
            height: '50%',
            position: 'relative',
          })}
        >
          <Image
            src={logoImage}
            alt={title}
            layout="fill"
            objectFit="contain"
            objectPosition="left"
          />
        </figure>
        <p
          css={theme.mq({
            marginTop: '--space-4',
            color: 'white',
            fontSize: {
              base: '--fontSizes-xs',
              sm: '--fontSizes-md',
            },
          })}
        >
          {subtitle}
        </p>
      </div>
    </ExternalLink>
  )
})
