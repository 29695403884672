import { useState, forwardRef } from 'react'
import {
  useSpring,
  useChain,
  useSpringRef,
  animated,
  config,
} from 'react-spring'
import { useTheme, Theme } from '@emotion/react'

import { ResponsiveCSSObject, Size } from '@/styles/types'

import { sizes } from './theme'

type Props = {
  children: React.ReactNode
  isChecked?: boolean
  defaultIsChecked?: boolean
  onClick?: (event: React.MouseEvent<HTMLLabelElement>) => void
  sx?: ResponsiveCSSObject
  layerStyle?: string
  colorScheme?: keyof Theme['colors']
  sizing?: Size
  isRequired?: boolean
  isDisabled?: boolean
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const Checkbox = forwardRef(function Checkbox(
  {
    children,
    isChecked: isCheckedProp,
    defaultIsChecked = false,
    onClick,
    sx,
    layerStyle,
    colorScheme = 'primary',
    sizing = 'md',
    onChange,
    isDisabled,
    isRequired,
    ...rest
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement {
  const theme = useTheme()

  const [isChecked, setIsChecked] = useState<boolean>(defaultIsChecked ?? false)

  const _isChecked = isCheckedProp ?? isChecked
  const themeColors: string =
    (theme.colors[colorScheme] as any)[500] ?? theme.colors.gray[900]

  const checkSpringRef = useSpringRef()
  const checkSpring = useSpring({
    strokeDashoffset: _isChecked ? 0 : 100,
    config: { clamp: true, ...config.stiff },
    ref: checkSpringRef,
  })

  const backgroundSpringRef = useSpringRef()
  const backgroundSpring = useSpring({
    backgroundColor: _isChecked ? `${themeColors}` : `#FFFFFF`,
    opacity: _isChecked ? 1 : 0,
    config: { clamp: true, ...config.stiff },
    ref: backgroundSpringRef,
  })

  useChain(
    _isChecked
      ? [backgroundSpringRef, checkSpringRef]
      : [checkSpringRef, backgroundSpringRef],
    [0, 0.2],
  )

  const controlSize = (sizes[sizing] ?? {})['control']

  return (
    <label
      onClick={onClick}
      aria-label={rest['aria-label']}
      css={theme.mq({
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        display: 'flex',
        position: 'relative',
        alignItems: 'baseline',
        opacity: isDisabled ? 0.7 : 1,
        textAlign: 'left',
        ...(layerStyle ? theme.layerStyles[layerStyle] : {}),
        ...sx,
      })}
    >
      <input
        type="checkbox"
        ref={ref}
        checked={isChecked}
        required={isRequired}
        disabled={isDisabled}
        css={theme.mq({
          position: 'absolute',
          width: '1px',
          height: '1px',
          opacity: 0,
          top: '0',
          left: '0',
        })}
        onChange={(e) => {
          if (onChange) onChange(e)
          setIsChecked(e.currentTarget.checked)
        }}
        {...rest}
        aria-label={undefined}
      />
      <animated.div
        style={{
          backgroundColor: backgroundSpring.backgroundColor,
        }}
        css={theme.mq({
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          padding: '2px',
          border: `2px solid ${themeColors}`,
          flex: '0 0 auto',
          ...controlSize,
        })}
      >
        <svg
          viewBox="0 0 46 38"
          css={theme.mq({
            width: '--sizes-full',
            height: '--sizes-full',
            padding: 0,
            margin: 'auto',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeMiterlimit: 1.5,
          })}
        >
          <animated.path
            style={{
              strokeDashoffset: checkSpring.strokeDashoffset,
              opacity: backgroundSpring.opacity,
            }}
            d="M5.213,17.548l11.81,11.792l26.549,-26.542"
            css={theme.mq({
              fill: 'none',
              stroke: 'white',
              strokeWidth: '5.83px',
              strokeDasharray: '100',
            })}
          />
        </svg>
      </animated.div>
      <div
        css={theme.mq({
          marginLeft: '--space-2',
          userSelect: 'none',
          ...(sizes[sizing] ?? {})['label'],
        })}
      >
        {children}
      </div>
    </label>
  )
})
