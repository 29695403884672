import { useTheme } from '@emotion/react'
import { FiX } from 'react-icons/fi'

import { Button } from '@/components/Button'
import { ResponsiveCSSObject } from '@/styles/types'
import { useBreakpointValue } from '@/lib/hooks'

import { useModalContext } from './ModalContext'

type Props = {
  sx?: ResponsiveCSSObject
}

export function ModalCloseButton({ sx }: Props) {
  const theme = useTheme()

  const { onClose } = useModalContext()

  const IconSize = useBreakpointValue({
    base: '20px',
    md: '28px',
  })

  return (
    <Button
      variant="ghost"
      onClick={onClose}
      colorScheme="blackAlpha"
      css={theme.mq({
        padding: '--space-1',
        position: 'absolute',
        top: '--space-2',
        right: '--space-2',
        color: '--colors-gray-700',
        minWidth: 0,
        height: { base: '--space-7', md: '--space-9' },
        width: { base: '--space-7', md: '--space-9' },
        '& > span': {
          width: '--sizes-full',
          height: '--sizes-full',
        },
        ...sx,
      })}
      sizing="xs"
    >
      <FiX size={IconSize} />
    </Button>
  )
}
