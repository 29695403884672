import * as z from 'zod'

import { UTMDataSchema } from '@/lib/gtm'

export const schema = z.object({
  name: z.string().nonempty({ message: 'กรุณาใส่ชื่อของท่าน' }),
  email: z
    .string()
    .nonempty({ message: 'กรุณาใส่อีเมลของท่าน' })
    .email({ message: 'อีเมลไม่ถูกต้อง' }),
  tel: z
    .string()
    .regex(/^\d{10}$/, 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก')
    .nonempty({ message: 'กรุณาใส่เบอร์โทรศัพท์ของท่าน' }),
  companyName: z.string().nonempty({ message: 'กรุณากรอกชื่อบริษัท' }),
  detail: z.string(),
  acceptedTerm: z.boolean(),
  agreedToMarketingConsent: z.boolean(),
})

export type FormValue = z.infer<typeof schema>

export const payloadSchema = schema.extend({
  utm: UTMDataSchema,
})

export type PayloadValue = z.infer<typeof payloadSchema>
