import { createPortal } from 'react-dom'
import { useState, useEffect } from 'react'
import { ResponsiveCSSObject } from '@/styles/types'

import { ModalContextProvider } from './ModalContext'

type Props = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  children?: React.ReactNode
  sx?: ResponsiveCSSObject
}

export function Modal({ children, isOpen, onOpen, onClose }: Props) {
  const [portal, setPortal] = useState<Element>()

  useEffect(() => {
    setPortal(document.querySelector('#app-modal-portal') || document.body)
  }, [])

  if (!portal) return null

  return createPortal(
    <ModalContextProvider isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <div className="app-modal">{children}</div>
    </ModalContextProvider>,
    portal,
  )
}
