import { forwardRef } from 'react'
import { useTheme, Theme } from '@emotion/react'

import { useFormControlContext } from '@/components/FormControl/FormControlContext'
import { ResponsiveCSSObject, Size } from '@/styles/types'

import { variants, sizes, baseStyle } from './theme'

type Variant = keyof typeof variants

type Props = {
  colorScheme?: keyof Theme['colors']
  isInvalid?: boolean
  isRequired?: boolean
  isReadOnly?: boolean
  isDisabled?: boolean
  variant?: Variant
  sizing?: Size
  sx?: ResponsiveCSSObject
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const Input = forwardRef(function Input(
  {
    colorScheme = 'primary',
    isRequired = false,
    isInvalid = false,
    isReadOnly = false,
    isDisabled = false,
    variant = 'outline',
    sizing = 'md',
    sx,
    ...rest
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const theme = useTheme()
  const formControlContext = useFormControlContext()

  return (
    <input
      ref={ref}
      required={isRequired || formControlContext.isRequired}
      readOnly={isReadOnly}
      disabled={isDisabled}
      {...rest}
      css={theme.mq({
        ...baseStyle,
        ...sizes[sizing],
        ...variants[variant](colorScheme),
        ...(!isInvalid && { '&:invalid': {} }),
        ...sx,
      })}
    />
  )
})
