import { ResponsiveCSSObject, Size } from '@/styles/types'

function variantGhost(colorScheme: string) {
  if (colorScheme === 'gray') {
    return {
      color: 'inherit',
      '&:hover': {
        backgroundColor: '--colors-gray-100',
      },
      '&:active': { backgroundColor: '--colors-gray-200' },
    }
  }

  return {
    color: `--colors-${colorScheme}-600`,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: `--colors-${colorScheme}-50`,
    },
    '&:active': {
      backgroundColor: `--colors-${colorScheme}-100`,
    },
  }
}

function variantOutline(colorScheme: string) {
  const borderColor = '--colors-gray-100'
  return {
    border: '1px solid',
    borderColor: colorScheme === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(colorScheme),
  }
}

type AccessibleColor = {
  bg?: string
  color?: string
  hoverBg?: string
  activeBg?: string
}

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
}

function variantSolid(colorScheme: string) {
  if (colorScheme === 'gray') {
    const backgroundColor = '--colors-gray-100'

    return {
      backgroundColor,
      '&:hover': {
        backgroundColor: '--colors-gray-200',
        '&:disabled': {
          backgroundColor,
        },
      },
      '&:active': { backgroundColor: '--colors-gray-300' },
    }
  }

  const {
    bg = `--colors-${colorScheme}-500`,
    color = 'white',
    hoverBg = `--colors-${colorScheme}-600`,
    activeBg = `--colors-${colorScheme}-700`,
  } = accessibleColorMap[colorScheme] || {}

  return {
    backgroundColor: bg,
    color: color,
    '&:hover': {
      backgroundColor: hoverBg,
      '&:disabled': {
        backgroundColor: bg,
      },
    },
    '&:active': { backgroundColor: activeBg },
  }
}

function variantLink(colorScheme: string) {
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    color: `--colors-${colorScheme}-500`,
    '&:hover': {
      textDecoration: 'underline',
      '&:disabled': {
        textDecoration: 'none',
      },
    },
    '&:active': {
      color: `--colors-${colorScheme}-500`,
    },
  }
}

const variantUnstyled = () => {
  return {
    backgroundColor: 'none',
    color: 'inherit',
    display: 'inline',
    lineHeight: 'inherit',
    m: 0,
    p: 0,
  }
}

export const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  unstyled: variantUnstyled,
}

export const sizes: Partial<Record<Size, ResponsiveCSSObject>> = {
  xl: {
    height: '--space-18',
    minWidth: '256px',
    fontSize: '--fontSizes-2xl',
    paddingLeft: '--space-6',
    paddingRight: '--space-6',
  },
  lg: {
    height: '--space-12',
    minWidth: '--space-12',
    fontSize: '--fontSizes-lg',
    paddingLeft: '--space-6',
    paddingRight: '--space-6',
  },
  md: {
    height: '--space-10',
    minWidth: '--space-10',
    fontSize: '--fontSizes-md',
    paddingLeft: '--space-4',
    paddingRight: '--space-4',
  },
  sm: {
    height: '--space-8',
    minWidth: '--space-8',
    fontSize: '--fontSizes-sm',
    paddingLeft: '--space-3',
    paddingRight: '--space-3',
  },
  xs: {
    height: '--space-6',
    minWidth: '--space-6',
    fontSize: '--fontSizes-xs',
    paddingLeft: '--space-2',
    paddingRight: '--space-2',
  },
}
